import * as React from 'react';
import styled from 'styled-components/macro';
import { StyleConstants } from 'styles/StyleConstants';
import { Nav } from './Nav';
import { PageWrapperSidebar } from '../PageWrapper';

export function SideBar() {
  return (
    <WrapperSidebar>
      <PageWrapperSidebar>
        <Nav />
      </PageWrapperSidebar>
    </WrapperSidebar>
  );
}

const WrapperSidebar = styled.header`
  box-shadow: 0 1px 0 0 ${p => p.theme.borderLight};
  /* height: ${StyleConstants.SIDE_BAR_HEIGHT_DASHBOARD}; */
  height: 100%;
  /* margin-top: calc(100vh - 6rem); */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 250px;
  background-color: #fff !important;
  z-index: 8888;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background-color: ${p =>
      p.theme.background.replace(
        /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
        'rgba$1,0.75)',
      )};
  }
`;

import * as React from 'react';
import styled from 'styled-components/macro';
import { StyleConstants } from 'styles/StyleConstants';
import { PageWrapperDashboard } from '../PageWrapper';
// import logo from './assets/LogoSvg.svg';

interface DashboardNavbar {
  children: any;
}

export function DashboardNavbar({ children }) {
  return (
    <>
      <WrapperDashboard>
        <PageWrapperDashboardNav>{children}</PageWrapperDashboardNav>
      </WrapperDashboard>
    </>
  );
}

const WrapperDashboard = styled.header`
  box-shadow: 0 1px 0 0 ${p => p.theme.borderLight};
  height: ${StyleConstants.NAV_BAR_HEIGHT_DASHBOARD};
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 250px);
  background-color: #fff !important;
  z-index: 9999;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
    background-color: ${p =>
      p.theme.background.replace(
        /rgba?(\(\s*\d+\s*,\s*\d+\s*,\s*\d+)(?:\s*,.+?)?\)/,
        'rgba$1,0.75)',
      )};
  }

  ${PageWrapperDashboard} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
  }
`;

const PageWrapperDashboardNav = styled(PageWrapperDashboard)`
  justify-content: space-around !important;
`;
const Container = styled.div`
  display: flex;
  width: calc(100vw - 20px);
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  padding: 10px 0px;
`;

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faCalendar,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { relative } from 'path';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Search } = Input;

interface InputSearchType {
  onChangeUser: any;
  onChangeDateFrom: any;
  onChangeDateTo: any;
  onPress: any;
  dateValueFrom: any;
  dateValueTo: any;
  sum: any;
  sumTime: any;
  searchTrigger: any;
  searchTriggerButton: any;
  onChangeAntdCalendar: any;
  onOk: any;
}

const convertMinutesToDuration = minutes => {
  var months = Math.floor(minutes / (30 * 24 * 60)); // Assuming 30 days in a month
  var days = Math.floor((minutes % (30 * 24 * 60)) / (24 * 60));
  var hours = Math.floor((minutes % (24 * 60)) / 60);
  var remainingMinutes = minutes % 60;

  var duration = '';
  if (months > 0) {
    duration += months + ' tháng, ';
  }
  if (days > 0) {
    duration += days + ' ngày, ';
  }
  if (hours > 0) {
    duration += hours + ' giờ, ';
  }
  if (remainingMinutes > 0) {
    duration += remainingMinutes + ' phút';
  }

  return duration;
};

const InputSearch: React.FC<InputSearchType> = (props: InputSearchType) => {
  const [showCalendarFrom, setShowCalendarFrom] = useState(false);
  const [showCalendarTo, setShowCalendarTo] = useState(false);
  // const [valueCalendarFrom, setValueCalendarFrom] = useState('');
  // const [valueCalendarTo, setValueCalendarTo] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  // const handleCalendarChangeFrom: any = (date: any) => {
  //   setValueCalendarFrom(date);
  // };
  // const handleCalendarChangeTo: any = (date: any) => {
  //   setValueCalendarTo(date);
  // };
  const [dateState, setDateState] = useState(new Date());
  const changeDate = e => {
    setDateState(e);
  };
  // console.log(dateState);

  //From calendar
  const calendarFromRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        calendarFromRef.current &&
        !calendarFromRef.current.contains(event.target)
      ) {
        // console.log('Redddddd flag');
        setShowCalendarFrom(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // To calendar
  const calendarToRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        calendarToRef.current &&
        !calendarToRef.current.contains(event.target)
      ) {
        setShowCalendarTo(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleFormDateClickFrom = event => {
    event.stopPropagation();
    setShowCalendarFrom(prevState => !prevState);
    setShowCalendarTo(false);
  };
  const handleFormDateClickTo = event => {
    event.stopPropagation();
    setShowCalendarTo(prevState => !prevState);
    setShowCalendarFrom(false);
  };

  return (
    <>
      {/* <CalendarStyledFrom
        onChange={props.onChangeDateFrom}
        goToRangeStartOnSelect={true}
        // value={moment(valueCalendarFrom).format('DD/MM/YYYY')}
      /> */}

      <Container className="">
        <InputWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // eslint-disable-next-line no-dupe-keys
            }}
          >
            <label htmlFor="user" style={{ fontWeight: '500' }}>
              Tìm kiếm nhân viên:
            </label>
            <Space direction="vertical" size={12}>
              <Search
                placeholder="Nhập tên nhân viên"
                allowClear
                size={'middle'}
                onChange={props.onChangeUser}
                onKeyDown={props.onPress}
                style={{ borderRadius: '6px' }}
              />
            </Space>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <label htmlFor="user" style={{ fontWeight: '500' }}>
              Tìm kiếm theo khoảng:
            </label>
            <Space direction="vertical" size={12}>
              {/* <DatePicker showTime onChange={onChange} onOk={onOk} /> */}
              <RangePicker
                showTime={false}
                format="DD/MM/YYYY"
                onChange={props.onChangeAntdCalendar}
                onOk={props.onOk}
                placeholder={['ngaỳ bắt đầu', 'ngày kết thúc']}
                defaultValue={[dayjs().startOf('day'), dayjs().endOf('day')]}
                // defaultValue={[`${Date.now()}`, `${Date.now()}`]}
              />
            </Space>
            {/* <ButtonStyled onClick={props.searchTriggerButton}>
              Tìm kiếm theo khoảng
            </ButtonStyled> */}
          </div>
          <ButtonStyled onClick={props.searchTriggerButton}>
            Tìm kiếm
          </ButtonStyled>
        </InputWrapper>
      </Container>
      <Flex>
        <h3>Tổng số người đi muộn: {props.sum}</h3>
        {props.sumTime && (
          <h3>
            Tổng số giờ đi muộn: {convertMinutesToDuration(props.sumTime)}
          </h3>
        )}
      </Flex>
    </>
  );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Flex = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  h3 {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
const SearchIcon = styled.div`
  margin-left: 5px;
  padding: 6px;
  background-color: #008c4f;
  border-radius: var(--bs-border-radius);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    /* Additional styles for the active state */
  }
  &:active {
    box-shadow: 0 0 0 0.25rem rgba(1, 102, 63, 0.25);
    /* Additional styles for the active state */
  }
`;
const SearchIconRight = styled(SearchIcon)`
  margin-left: 0px;
  margin-right: 5px;
`;
const SearchIconRed = styled(SearchIcon)`
  background-color: red;
`;
const TimeDistance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`;
const CalendarStyled = styled(Calendar)`
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 10px;
  width: 230px !important;
  position: absolute;
  border-radius: 10px;
  border: 3px solid #008c4f !important;
  color: #008c4f !important;
`;
const CalendarStyledFrom = styled(CalendarStyled)`
  top: 0px;
  right: 20rem;
`;
const CalendarStyledTo = styled(CalendarStyled)`
  top: 0px;
  right: 20rem;
`;
const ButtonStyled = styled.button`
  border-radius: 5px;
  width: 20%;
  border: 3px solid #008c4f;
  padding: 5px;
  background-color: #008c4f;
  color: #fff;
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    box-shadow: 0 0 0 0.25rem rgba(1, 102, 63, 0.25);
    /* Additional styles for the active state */
  }
`;
export default InputSearch;

// `${QueryUrl}/total?start_date=${moment(Date.now()).format(
//   'DD/MM/YYYY',
// )}&end_date=${moment(Date.now()).format('DD/MM/YYYY')}`,

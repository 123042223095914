// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-family: sans-serif;
  font-size: 14px;
}

table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.TrowHightLight:nth-child(1) {
  font-weight: 700;
}
.TrowHightLight:nth-child(1) td {
  color: #9d0208;
}

.TrowHightLight:nth-child(2) {
  font-weight: 700;
}
.TrowHightLight:nth-child(2) td {
  color: #9d0208;
}
.TrowHightLight:nth-child(3) {
  font-weight: 700;
}
.TrowHightLight:nth-child(3) td {
  color: #9d0208;
}

.TrowHightLight:nth-child(4) {
  font-weight: 700;
}
.TrowHightLight:nth-child(4) td {
  color: #9d0208;
}
.TrowHightLight:nth-child(5) {
  font-weight: 700;
}
.TrowHightLight:nth-child(5) td {
  color: #9d0208;
}
.time-sort:hover {
  color: #008c4f;
}
.result-sort:hover {
  color: #008c4f;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/TablePageLateEarly/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":["html {\n  font-family: sans-serif;\n  font-size: 14px;\n}\n\ntable {\n  border: 1px solid lightgray;\n}\n\ntbody {\n  border-bottom: 1px solid lightgray;\n}\n\nth {\n  border-bottom: 1px solid lightgray;\n  border-right: 1px solid lightgray;\n  padding: 2px 4px;\n}\n\ntfoot {\n  color: gray;\n}\n\ntfoot th {\n  font-weight: normal;\n}\n\n.TrowHightLight:nth-child(1) {\n  font-weight: 700;\n}\n.TrowHightLight:nth-child(1) td {\n  color: #9d0208;\n}\n\n.TrowHightLight:nth-child(2) {\n  font-weight: 700;\n}\n.TrowHightLight:nth-child(2) td {\n  color: #9d0208;\n}\n.TrowHightLight:nth-child(3) {\n  font-weight: 700;\n}\n.TrowHightLight:nth-child(3) td {\n  color: #9d0208;\n}\n\n.TrowHightLight:nth-child(4) {\n  font-weight: 700;\n}\n.TrowHightLight:nth-child(4) td {\n  color: #9d0208;\n}\n.TrowHightLight:nth-child(5) {\n  font-weight: 700;\n}\n.TrowHightLight:nth-child(5) td {\n  color: #9d0208;\n}\n.time-sort:hover {\n  color: #008c4f;\n}\n.result-sort:hover {\n  color: #008c4f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

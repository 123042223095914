import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputSearch from './Features/inputSearchLogic';
import './index.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';

// importPagination
import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';

// test AntdCalendar
// import { DatePicker } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { useNavigate } from 'react-router-dom';
import { DashboardNavbar } from 'app/components/DashboardNavbar';
import moment from 'moment';
import { convertMinutesToHourAndMinutes } from '../UserPage/Features/inputSearchLogic';

type LateEarly = {
  user_id: string;
  totalTime: string;
  totalResult: string;
  user_name: string;
};

const fuzzyFilter: any = (row, columnId, value, addMeta) => {
  return;
};

const dateNow = moment(Date.now()).format('DD/MM/YYYY');

export function ReactTable() {
  const columnHelper = createColumnHelper<LateEarly>();
  const columns = [
    // columnHelper.accessor('user_id', {
    //   header: () => 'Mã nhân viên',
    //   cell: info => info.getValue(),
    // }),
    columnHelper.accessor('user_name', {
      header: () => 'Tên nhân viên',
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('totalTime', {
      header: () => 'Tổng thời gian (Giờ + Phút)',
      cell: info => convertMinutesToHourAndMinutes(info.renderValue()),
    }),
    columnHelper.accessor('totalResult', {
      header: () => 'Tổng số lần',
      cell: info => info.renderValue(),
    }),
  ];

  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [sum, setSum] = React.useState(0);
  const [sumTime, setSumTime] = React.useState();
  // const [isFalse, setFalse] = React.useState(false);
  // State User
  const [inputValueUser, setInputValueUser] = useState('');
  // const [inputValueDate, setInputValueDate] = useState<any | null>(Date.now());

  // State listAll
  // const [listAll, setListAll] = useState(true);
  // State Calendar
  const [valueCalendarFrom, setValueCalendarFrom] = useState(dateNow);
  const [valueCalendarTo, setValueCalendarTo] = useState(dateNow);

  // State Pagination
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  // console.log(page);

  const handleCalendarChangeFrom: any = (date: any) => {
    // console.log(date);
    setValueCalendarFrom(date);
  };
  const handleCalendarChangeTo: any = (date: any) => {
    setValueCalendarTo(date);
  };

  const inputSearch = {
    user: inputValueUser,
    datefrom: valueCalendarFrom,
    dateto: valueCalendarTo,
    // listAll,
    page: page,
  };

  console.log(inputSearch);

  const onChangeAntdCalendar = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    setValueCalendarFrom(dateString[0]);
    setValueCalendarTo(dateString[1]);
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
  };
  const onOk = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
  ) => {
    // console.log('onOk: ', value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickHeader: any = data => {
    console.log(data);
    if (data === 'totalTime') {
      fetchDataSearch(inputSearch, 'totalTime:desc');
    } else if (data === 'totalResult') {
      fetchDataSearch(inputSearch, 'totalResult:desc');
    }
  };

  function convertResultToPayload(payload: any) {
    return payload.map(record => {
      const { results, ...rest } = record;
      return {
        ...rest,
        ...results,
      };
    });
  }

  const QueryUrl = 'https://api-web.lumier.lumi.com.vn/late-early';

  const fetchData = () => {
    axios
      // .get(`https://api-web.lumier.lumi.com.vn/late-early/?date=${formattedDate}`)
      .get(
        `${QueryUrl}/total?start_date=${moment(Date.now()).format(
          'DD/MM/YYYY',
        )}&end_date=${moment(Date.now()).format('DD/MM/YYYY')}&status=2`,
      )
      .then(response => {
        // Handle the successful response here
        const responseData = response.data;
        console.log(responseData);
        setData(convertResultToPayload(responseData.results));
        setSum(responseData.totalResult);
        setSumTime(responseData.totalTime);
        setTotalPage(responseData.totalPages);
      })
      .catch(error => {
        // Handle the error here
        console.log(error);
      });
  };

  const handleChangeUser: any = event => {
    if (inputSearch.user === '') {
      setPage(1);
    }
    setInputValueUser(event.target.value);
    // setFalse(false);
  };
  // const handleChangeDate: any = (date: any) => {
  //   // setInputValueDate(date);
  //   // fetchDataDate();
  // };
  const handleSetTriggerSearch: any = () => {
    if (inputValueUser !== '') {
      setPage(1);
      fetchDataSearch(inputSearch);
      // setListAll(false);
    }
  };
  const handleSetTriggerSearchTimeButton: any = () => {
    setPage(1);
    fetchDataSearch(inputSearch);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchDataSearch(inputSearch);
      // setListAll(false);
    }
  };

  // fetch page pagination
  const fetchDataPagination = React.useCallback(page => {
    axios
      .get(`https://api-web.lumier.lumi.com.vn/late-early/?page=${page}`)
      .then(response => {
        const responseData = response.data;
        if (responseData.success === false) {
          // setFalse(true);
          setData([]);
        }
        setData(convertResultToPayload(responseData.results));
        setSum(responseData.totalResult);
        setSumTime(responseData.totalTime);

        setTotalPage(responseData.totalPages);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  // console.log('dataPageeeeeeeeeee', inputSearch.page);
  // console.log(inputSearch);
  //handle Pagination
  const handlePagination: any = page => {
    if (inputSearch.user === '') {
      fetchDataPagination(page);
    } else {
      // console.log('Case username rỗng', inputSearch);
      fetchDataSearch(inputSearch, page);
    }
  };

  // console.log(inputSearch);
  const fetchDataSearch = React.useCallback((data: any, sort?: any) => {
    console.log(sort);
    if (data.user && data.datefrom && data.dateto) {
      axios
        .get(
          sort
            ? `${QueryUrl}/total?user_name=${data.user}&start_date=${data.datefrom}&end_date=${data.dateto}&status=2&sortBy=${sort}`
            : `${QueryUrl}/total?user_name=${data.user}&start_date=${data.datefrom}&end_date=${data.dateto}&status=2`,
        )
        .then(response => {
          const responseData = response.data;
          if (responseData.success === false) {
            // setFalse(true);
            setData([]);
          }
          setData(convertResultToPayload(responseData.results));
          setSum(responseData.totalResult);
          setSumTime(responseData.totalTime);
          setTotalPage(responseData.totalPages);
        })
        .catch(error => {
          if (error.response && error.response.status === 500) {
            // Handle 500 error
            console.log('Server error: Internal Server Error');
            // You can display an error message to the user or perform other actions
          } else {
            // Handle other errors
            console.log('An error occurred:', error.message);
          }
        });
    } else if (data.user && data.datefrom === '' && data.dateto === '') {
      axios
        .get(
          sort
            ? `${QueryUrl}/total?user_name=${data.user}&status=2&sortBy=${sort}`
            : `${QueryUrl}/total?user_name=${data.user}&status=2`,
        )
        .then(response => {
          const responseData = response.data;
          if (responseData.success === false) {
            // setFalse(true);
            setData([]);
          }
          setData(convertResultToPayload(responseData.results));
          setSum(responseData.totalResult);
          setSumTime(responseData.totalTime);
          setTotalPage(responseData.totalPages);
        })
        .catch(error => {
          console.log(error);
        });
    } else if (data.user === '' && data.datefrom && data.dateto) {
      console.log(sort);
      axios
        .get(
          sort
            ? `${QueryUrl}/total?start_date=${data.datefrom}&end_date=${data.dateto}&status=2&sortBy=${sort}`
            : `${QueryUrl}/total?start_date=${data.datefrom}&end_date=${data.dateto}&status=2`,
        )
        .then(response => {
          const responseData = response.data;
          if (responseData.success === false) {
            // setFalse(true);
            setData([]);
          }
          setData(convertResultToPayload(responseData.results));
          setSum(responseData.totalResult);
          setSumTime(responseData.totalTime);
          setTotalPage(responseData.totalPages);
        })
        .catch(error => {
          console.log(error);
        });
    } else if (data.user === '' && data.datefrom === '' && data.dateto === '') {
      axios
        .get(
          sort
            ? `${QueryUrl}/total?status=2&sortBy=${sort}`
            : `${QueryUrl}/total?status=2`,
        )
        .then(response => {
          const responseData = response.data;
          setData(convertResultToPayload(responseData.results));
          setSum(responseData.totalResult);
          setSumTime(responseData.totalTime);
          setTotalPage(responseData.totalPages);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  });

  return (
    <>
      <DashboardNavbar>
        <InputSearch
          onChangeUser={handleChangeUser}
          onChangeDateFrom={handleCalendarChangeFrom}
          onChangeDateTo={handleCalendarChangeTo}
          onPress={handleKeyPress}
          dateValueFrom={valueCalendarFrom}
          dateValueTo={valueCalendarTo}
          searchTrigger={handleSetTriggerSearch}
          searchTriggerButton={handleSetTriggerSearchTimeButton}
          sum={sum}
          sumTime={sumTime}
          onChangeAntdCalendar={onChangeAntdCalendar}
          onOk={onOk}
        />
      </DashboardNavbar>
      <div className="p-2">
        <Table striped bordered hover>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    onClick={e => {
                      e.preventDefault();
                      handleClickHeader(header.id);
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="TheadHightlight">
            {table.getRowModel().rows.map(row => (
              // eslint-disable-next-line no-sequences
              // console.log(row.original.user_id),
              <tr
                key={row.id}
                onClick={() => navigate(`/${row.original.user_id}`)}
                style={{ cursor: 'pointer' }}
                className="TrowHightLight"
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        <PaginationControl
          page={page}
          between={10}
          total={totalPage}
          limit={1}
          changePage={page => {
            handlePagination(page);
            setPage(page);
          }}
          ellipsis={1}
        />
      </div>
    </>
  );
}

// import * as React from 'react';
// import styled from 'styled-components/macro';
// import { NavLink } from 'react-router-dom';

// export function Nav() {
//   return (
//     <>
//       <Ul>
//         <Li>
//           <LinkStyled to={`/table_late_early`}>Đi muộn - về sớm</LinkStyled>
//         </Li>
//         <Li>
//           <LinkStyled to={`/table_take_leaves`}>Ngày nghỉ</LinkStyled>
//         </Li>
//         <Li>
//           <LinkStyled to={`/table_work_remote`}>Làm Online</LinkStyled>
//         </Li>
//       </Ul>
//     </>
//   );
// }

// const Ul = styled.ul`
//   display: flex;
//   flex-direction: column;
//   margin-top: 4.4rem;
//   width: calc(100% - 70px);
//   list-style: none;
// `;

// const Li = styled.li`
//   text-transform: uppercase;
//   font-size: 15px;
//   padding-bottom: 10px;
//   .active {
//     color: #fff;
//     background-color: #008c4f;
//     padding: 5px;
//     border-radius: 10px;
//   }
// `;
// const LinkStyled = styled(NavLink)`
//   color: #000;
//   text-decoration: none;
//   font-weight: 700;
// `;

import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, NavLink } from 'react-router-dom';
import logo from './assets/LogoSvg.svg';

export function Nav() {
  return (
    <SidebarWrapper>
      <Link to="/table_late_early">
        <Logo src={logo} alt="Logo-lumi" />
      </Link>
      <Ul>
        <Li>
          <LinkStyled to={`/table_late_early`}>Đi muộn - về sớm</LinkStyled>
        </Li>
        <Li>
          <LinkStyled to={`/table_take_leaves`}>Ngày nghỉ</LinkStyled>
        </Li>
        <Li>
          <LinkStyled to={`/table_work_remote`}>Làm Online</LinkStyled>
        </Li>
      </Ul>
    </SidebarWrapper>
  );
}

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`;
const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  /* margin-top: 4.4rem; */
  width: calc(100%);
  list-style: none;
`;

const Li = styled.li`
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 10px;
  .active {
    color: #fff;
    background-color: #008c4f;
    padding: 5px;
    border-radius: 10px;
  }
`;
const LinkStyled = styled(NavLink)`
  color: #000;
  text-decoration: none;
  font-weight: 700;
`;
const Logo = styled.img`
  padding: 10px 0px;
  margin-bottom: 100px;
`;

import styled from 'styled-components/macro';

export const PageWrapper = styled.div`
  width: 960px;
  margin: 0 auto;
  padding: 0 1.5rem;
  box-sizing: content-box;
`;

export const PageWrapperDashboard = styled.div`
  width: calc(100vw - 70px);
  margin: 0 auto;
  box-sizing: border-box;
`;

export const PageWrapperTable = styled.div`
  width: calc(100% - 250px);
  position: absolute;
  right: 0;
  left: 10%;
  margin: 6rem auto 0;
  padding-left: 4rem;
  box-sizing: border-box;
`;

export const PageWrapperUserpage = styled.div`
  width: calc(100% - 250px);
  position: absolute;
  right: 0;
  left: 10%;
  margin: 6rem auto 0;
  margin-top: 0rem !important;
  padding-left: 4rem;
  box-sizing: border-box;
`;

export const PageWrapperSidebar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { createGlobalStyle } from 'styled-components';
import { StyleConstants } from './StyleConstants';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-top: ${StyleConstants.NAV_BAR_HEIGHT};
    background-color: ${p => p.theme.background};
  }

  body.fontLoaded {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

.react-calendar__navigation__arrow  {
  font-size: 25px;
} 
.react-calendar__navigation__label{
  font-weight: 700;
  color: #008c4f;
  font-size: 11px;
}
.react-calendar__tile.react-calendar__month-view__days__day > abbr {
  font-weight: 700 !important;
  font-size: 13px !important;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--now {
  background: #008c4f;
  color: #fff;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--now:hover{
  background: #008c4f;
  color: #fff;
}
a.page-link {
  color: #008c4f !important;
}
.active .page-link{
  background-color: #008c4f !important;
  color: #fff !important;
  border-color: #008c4f !important; 
}
.ant-input-search-button{
  display: none;
}
.ant-picker-dropdown {
  z-index: 999999;
}
`;

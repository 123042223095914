import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faCalendar,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { relative } from 'path';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

// useParams
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Search } = Input;

interface InputSearchType {
  onChangeUser?: any;
  onChangeDateFrom?: any;
  onChangeDateTo?: any;
  onPress?: any;
  dateValueFrom?: any;
  dateValueTo?: any;
  sum?: any;
  sumTime?: any;
  searchTrigger?: any;
  searchTriggerButton?: any;
  onChangeAntdCalendar?: any;
  onOk?: any;
  user_name?: any;
  total_data?: any;
}

function formatDate(epochTime) {
  const date = new Date(epochTime);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

export function convertMinutesToHourAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours} giờ ${minutes} phút`;
}

type Personal = {
  late_early_total_result: string;
  take_leaves_total_result: string;
  take_leaves_saturday_total_result: string;
  work_remote_total_result: string;
};

const columnHelper = createColumnHelper<Personal>();

const columns = [
  columnHelper.accessor('late_early_total_result', {
    header: () => '',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('late_early_total_result', {
    header: () => 'Đi muộn về sớm',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('take_leaves_total_result', {
    header: () => 'Ngày nghỉ',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('take_leaves_saturday_total_result', {
    header: () => 'Ngày nghỉ thứ 7',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('work_remote_total_result', {
    header: () => 'Làm online',
    cell: info => {
      if (Number(info.getValue()) === 1) {
        return (
          <>
            <span style={{ color: '#9d0208' }}>Đi muộn</span>
          </>
        );
      } else {
        return (
          <>
            <span style={{ color: '#f7b538' }}>Về sớm</span>
          </>
        );
      }
    },
  }),
];

const fuzzyFilter: any = (row, columnId, value, addMeta) => {
  return;
};

const InputSearchLogic: React.FC<InputSearchType> = (
  props: InputSearchType,
) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [showCalendarFrom, setShowCalendarFrom] = useState(false);
  const [showCalendarTo, setShowCalendarTo] = useState(false);
  // const [valueCalendarFrom, setValueCalendarFrom] = useState('');
  // const [valueCalendarTo, setValueCalendarTo] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  // const handleCalendarChangeFrom: any = (date: any) => {
  //   setValueCalendarFrom(date);
  // };
  // const handleCalendarChangeTo: any = (date: any) => {
  //   setValueCalendarTo(date);
  // };
  const [dateState, setDateState] = useState(new Date());
  const changeDate = e => {
    setDateState(e);
  };
  // console.log(dateState);

  //From calendar
  const calendarFromRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        calendarFromRef.current &&
        !calendarFromRef.current.contains(event.target)
      ) {
        // console.log('Redddddd flag');
        setShowCalendarFrom(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // To calendar
  const calendarToRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        calendarToRef.current &&
        !calendarToRef.current.contains(event.target)
      ) {
        setShowCalendarTo(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleFormDateClickFrom = event => {
    event.stopPropagation();
    setShowCalendarFrom(prevState => !prevState);
    setShowCalendarTo(false);
  };
  const handleFormDateClickTo = event => {
    event.stopPropagation();
    setShowCalendarTo(prevState => !prevState);
    setShowCalendarFrom(false);
  };

  const [data, setData] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  });

  return (
    <>
      <Container className="">
        <InputWrapper>
          <div style={{ width: '200px', color: '#008c4f', fontSize: '20px' }}>
            <label htmlFor="user" style={{ fontWeight: '700' }}>
              {props.user_name && props.user_name}
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <label htmlFor="user" style={{ fontWeight: '500' }}>
              Tìm kiếm theo khoảng:
            </label>
            <Space direction="vertical" size={12}>
              {/* <DatePicker showTime onChange={onChange} onOk={onOk} /> */}
              <RangePicker
                showTime={false}
                format="DD/MM/YYYY"
                onChange={props.onChangeAntdCalendar}
                onOk={props.onOk}
                placeholder={['ngaỳ bắt đầu', 'ngày kết thúc']}
              />
            </Space>
            {/* <ButtonStyled onClick={props.searchTriggerButton}>
              Tìm kiếm theo khoảng
            </ButtonStyled> */}
          </div>
          <ButtonStyled onClick={props.searchTriggerButton}>
            Tìm kiếm
          </ButtonStyled>
        </InputWrapper>
      </Container>
      {/* <TableStyled striped bordered hover>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <a href="#take_leaves_tabledfwefwfwfwfwfwfwfwf">
                  <th
                    key={header.id}
                    // onClick={() => {
                    //   if (header.id === 'late_early_total_result') {
                    //     navigate(`/#${id}_late_early_table`);
                    //   } else if (
                    //     header.id === 'take_leaves_total_result' ||
                    //     header.id === 'take_leaves_saturday_total_result'
                    //   ) {
                    //     navigate(`/#take_leaves_table`);
                    //   } else {
                    //     navigate(`/${id}/#work_remotes_table`);
                    //   }
                    // }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                </a>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            // eslint-disable-next-line no-sequences
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tbody>
          {table.getRowModel().rows.map(row => (
            // eslint-disable-next-line no-sequences
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </TableStyled> */}
      <TableStyled striped bordered hover>
        <thead>
          <tr>
            <th></th>
            <th>
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                href="#late_early_table"
              >
                Đi muộn - về sớm
              </a>
            </th>
            <th>
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                href="#take_leaves_table"
              >
                Ngày nghỉ
              </a>
            </th>
            <th>
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                href="#take_leaves_table"
              >
                Ngày nghỉ thứ 7
              </a>
            </th>
            <th>
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                href="#work_remotes_table"
              >
                Làm online
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Tdstyled>
              <span
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: '700',
                }}
              >
                Tổng
              </span>
            </Tdstyled>
            {props.total_data ? (
              <>
                <Tdstyled>
                  {props.total_data.late_early.totalTime
                    ? convertMinutesToHourAndMinutes(
                        props.total_data.late_early.totalTime,
                      )
                    : 0 + ' giờ'}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.take_leave.totalTime
                    ? props.total_data.take_leave.totalTime + ' ngày'
                    : 0 + ' ngày'}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.take_leave_saturday.totalTime
                    ? props.total_data.take_leave_saturday.totalTime + ' ngày'
                    : 0 + ' ngày'}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.workRemote.totalTime
                    ? props.total_data.workRemote.totalTime + ' ngày'
                    : 0 + ' ngày'}
                </Tdstyled>
              </>
            ) : (
              <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
            {/* <Tdstyled>
              {props.total_data.late_early.totalTime
                ? props.total_data.late_early.totalTime
                : 0}
            </Tdstyled>
            <Tdstyled>
              {props.total_data.take_leave.totalTime
                ? props.total_data.late_early.totalTime
                : 0}
            </Tdstyled>
            <Tdstyled>
              {props.total_data.take_leave_saturday.totalTime
                ? props.total_data.late_early.totalTime
                : 0}
            </Tdstyled>
            <Tdstyled>
              {props.total_data.workRemote.totalTime
                ? props.total_data.late_early.totalTime
                : 0}
            </Tdstyled> */}
          </tr>
          <tr>
            <Tdstyled>
              <span
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: '700',
                }}
              >
                Số lần
              </span>
            </Tdstyled>
            {props.total_data ? (
              <>
                <Tdstyled>
                  {props.total_data.late_early.totalResults
                    ? props.total_data.late_early.totalResults
                    : 0}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.take_leave.totalResults
                    ? props.total_data.take_leave.totalResults
                    : 0}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.take_leave_saturday.totalResults
                    ? props.total_data.take_leave_saturday.totalResults
                    : 0}
                </Tdstyled>
                <Tdstyled>
                  {props.total_data.workRemote.totalResults
                    ? props.total_data.workRemote.totalResults
                    : 0}
                </Tdstyled>
              </>
            ) : (
              <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
        </tbody>
      </TableStyled>
    </>
  );
};

const Tdstyled = styled.td`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  text-align: center;
  font-weight: 700;
  color: #008c4f !important;
`;
const TableStyled = styled(Table)`
  width: 500px;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Flex = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  h3 {
    font-size: 20px;
  }
`;
const SearchIcon = styled.div`
  margin-left: 5px;
  padding: 6px;
  background-color: #008c4f;
  border-radius: var(--bs-border-radius);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    /* Additional styles for the active state */
  }
  &:active {
    box-shadow: 0 0 0 0.25rem rgba(1, 102, 63, 0.25);
    /* Additional styles for the active state */
  }
`;
const SearchIconRight = styled(SearchIcon)`
  margin-left: 0px;
  margin-right: 5px;
`;
const SearchIconRed = styled(SearchIcon)`
  background-color: red;
`;
const TimeDistance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`;
const CalendarStyled = styled(Calendar)`
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 10px;
  width: 230px !important;
  position: absolute;
  border-radius: 10px;
  border: 3px solid #008c4f !important;
  color: #008c4f !important;
`;
const CalendarStyledFrom = styled(CalendarStyled)`
  top: 0px;
  right: 20rem;
`;
const CalendarStyledTo = styled(CalendarStyled)`
  top: 0px;
  right: 20rem;
`;
const ButtonStyled = styled.button`
  border-radius: 5px;
  width: 20%;
  border: 3px solid #008c4f;
  padding: 5px;
  background-color: #008c4f;
  color: #fff;
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    box-shadow: 0 0 0 0.25rem rgba(1, 102, 63, 0.25);
    /* Additional styles for the active state */
  }
`;
export default InputSearchLogic;

import React from 'react';
import { DashboardNavbar } from 'app/components/DashboardNavbar';
import { ReactTable } from './ReactTable';
import { SideBar } from 'app/components/Sidebar';
import { PageWrapperTable } from 'app/components/PageWrapper';
import { Helmet } from 'react-helmet-async';

export function TablePageLateEarly() {
  return (
    <>
      <Helmet>
        <title>Đi sớm về muộn</title>
        <meta name="description" content="bảng đi sớm - về muộn" />
      </Helmet>
      {/* <DashboardNavbar /> */}
      <SideBar />
      <PageWrapperTable>
        <ReactTable />
      </PageWrapperTable>
    </>
  );
}
